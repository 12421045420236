<style>
div.address {
	display: block;
	width: 95%;
	margin: auto;
	display:flex;
	text-align: center;
	justify-content: center;
	flex-wrap:wrap;
}
div.address div.address-list:before {
	content: "\00a0\2022\00a0";
	font-family: 'monospace';
}
div.address div.address-list:first-child:before {
	content: '\00a0';
	font-family: 'monospace';
}
</style>
<template>
	<div class="text-center m-4" v-if="loading">
		<b-spinner variant="primary" label="Spinning"></b-spinner>
	</div>
	<div v-else-if="loadingError" class="d-flex vh-100 vw-100">
		<h1 style="margin:auto auto;">There was an error retrieving your status. Please try again later, or contact an administrator</h1>
	</div>
	<div v-else-if="notFound" class="d-flex vh-100 vw-100">
		<h1 style="margin:auto auto;">Nothing was found here.</h1>
	</div>
	<div v-else>
		<b-modal id="confirmUnsubscribe" variant="light" dismissible class="w-50 mx-auto">
			<p>Are you sure you'd like to unsubscribe? This action can not be undone.</p>
			<div slot="modal-footer">
				<button class="btn-sm btn-outline-dark mx-2" @click="unsubscribe()">Unsubscribe</button>
			  	<button class="btn-sm btn-outline-danger mx-2" @click="$bvModal.hide('confirmUnsubscribe')">Cancel</button>
			  </div>
		</b-modal>
		<div class="content-container mt-5 mb-5">
			<div class="col text-center w-100 mx-auto" v-if="this.client != null">
				<img v-if="this.client.logo != null" height="auto" width="300" class="client-info-logo" :src="this.client.logo"/>
				<img v-else height="auto" width="150" class="client-info-logo" src="../assets/img/mdme-logo.png" />
				<h4 class="mt-2">{{ client.name || `Client #${client.id}` }}</h4>
				<div v-if="client.contact" class="address mt-2">
					<div class="address-list" v-if="client.contact.phoneNumber">{{ client.contact.phoneNumber }}</div>
					<div class="address-list" v-if="client.contact.supportEmail"><a :href="'mailto:' + client.contact.supportEmail">{{ client.contact.supportEmail }}</a></div>
					<div class="address-list" v-if="client.contact.website"><a :href="client.contact.website">{{ client.contact.website }}</a></div>
					<div class="address-list" v-if="client.contactAddr">{{ client.contactAddr }}</div>
				</div>
			</div>
			<div v-if="consumerUnsubscribed" class="d-flex h-100 w-100">
				<h1 style="margin:auto auto;">You have unsubscribed. If you wish to undo this, please contact an administrator.</h1>
			</div>
			<div v-else>
				<button style="width:500px; max-width: 100vw; margin-left: auto;" class="btn-outline-blue" @click="$bvModal.show('confirmUnsubscribe');">Confirm unsubscribe</button>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios';
import router from '../router';
import { API } from 'aws-amplify';

export default {
	components: { },
	data() {
		return {
			client: null,
			loading: true,
			submitting: false,
			loadingError: false,
			notFound: false,
			consumerUnsubscribed: false,
		};
	},
	async mounted() {
		this.loading = true;
		try {
			// load in data
			let { clientID, consumerID, timelineID, taskUUID } = await this.$attrs;
			if (!clientID || !consumerID || !timelineID || !taskUUID) {
				// ignore everything, get kicked to the task screen which has beter handling of this all.
				router.push(`/dashboard/${clientID}/${consumerID}/${timelineID}/${taskUUID}`).catch(() => {});
				return;
			}
			const apiName = 'consumers';
			const path = `/consumers/${clientID}/${consumerID}/${timelineID}/${taskUUID}`;
			const payload = {
				path: path,
				httpMethod: 'get',
				headers: {
					'Content-Type' : 'application/json',
				},
			};
			let [taskData, error] = await API.get(apiName, path, payload).then(
				async (result) => {
					return [result, null];
				}
			).catch( (err) => {
				return [false, err];
			});

			let client = null;
			if (error != null || !taskData) {
				// TODO: handle 404 vs 500, right now just redirect to login.
				if (error == null) {
					this.notFound = true;
				} else if (!error.response || !error.response.status) {
					this.loadingError = true;
				} else if (error.response.status == 404) {
					this.notFound = true;
				} else if (error.response.status == 410) {
					this.consumerUnsubscribed = true;
				} else if (
					error.response.status != 406 &&
					error.response.status != 409 &&
					error.response.status != 425 &&
					error.response.status != 451
				) {
					// not unsubscribed and not one of these nonsense statuses.
					this.loadingError = true;
				}
				if (this.loadingError || this.notFound) {
					return;
				}
				client = error.response && error.response.data && error.response.data.client;
			} else {
				client = taskData.client;
			}

			this.client = client;
			if (this.client) {
				this.client.id = client.id;
				this.client.name = client.name;
				this.client.contact = client.contact;
				if (this.client.id != 0) {
					if (this.client.contact) {
						let addrParts = [];
						if (this.client.contact.address1) {
							addrParts.push(this.client.contact.address1);
						}
						if (this.client.contact.address2) {
							addrParts.push(this.client.contact.address2);
						}
						if (this.client.contact.address3) {
							addrParts.push(this.client.contact.address3);
						}
						if (this.client.contact.city) {
							addrParts.push(this.client.contact.city);
						}
						if (this.client.contact.state) {
							addrParts.push(this.client.contact.state);
						}
						if (this.client.contact.postalCode) {
							addrParts.push(this.client.contact.postalCode);
						}
						if (addrParts.length > 0) {
							this.client.contactAddr = addrParts.join(', ');
						}
					}


					let s3bucket = await this.$store.getters.getCurrentS3Bucket;
					let logoPath = `https://${s3bucket}.s3.amazonaws.com/${this.client.id}/logofile.png`;
					let self = this;
					await axios.get(logoPath).then(() => {
						self.client.logo = logoPath;
					}).catch((e) => {
						// do nothing, say nothing.
					});
				} else {
					this.client = null;
				}
			}

			// Logo set, inport task data
		} finally {
			this.loading = false;
			if (!this.consumerUnsubscribed) {
				let self = this;
				setTimeout(() => {
					self.$bvModal.show('confirmUnsubscribe');
				}, 500);

			}
		}
	},
	computed: {},
	methods: {
		async unsubscribe() {
			this.$bvModal.hide('confirmUnsubscribe');
			this.loading = true;
			let { clientID, consumerID, timelineID, taskUUID } = await this.$attrs;
			if (!clientID || !consumerID || !timelineID || !taskUUID) {
				// ignore everything, get kicked to login.
				router.push('/').catch(() => {});
				return;
			}
			const apiName = 'consumers';
			const payload = {
				path: `/consumers/${clientID}/${consumerID}/${timelineID}/${taskUUID}/unsubscribe`,
				httpMethod: 'post',
				headers: {
					'Content-Type' : 'application/json',
				},
				body: {}
			};

			let [unsubscribeResponse, error] = await API.post(apiName, payload.path, payload
			).then(
				async (result) => {
					return [result, null];
				}
			).catch( (err) => {
				return [false, err];
			});

			if (error != null || !unsubscribeResponse) {
				// TODO: handle 404 vs 500, right now just redirect to login.
				alert('Failed to unsubscribe');
			} else {
				this.consumerUnsubscribed = true;
			}
			this.loading = false;
		}
	}
}
</script>
